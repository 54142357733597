const Config = {
	buildVersion: '220130.2',
	dexieVersion: 1,
	firebaseConfig: {
		apiKey: "AIzaSyA9OvL8yoftvzbyUWO7bL7kUPsd6tPrte8",
		authDomain: "home-pwa.firebaseapp.com",
		projectId: "home-pwa",
		storageBucket: "home-pwa.appspot.com",
		messagingSenderId: "1074642604938",
		appId: "1:1074642604938:web:25cab06afb680b0660ae73"
	}
};

export default Config;

import React, { useEffect, useState } from 'react';
import { auth, authdb } from '../services/firebase';
import firebase from "firebase/compat/app";
import firestore from "firebase/firestore";

type ContextProps = {
	user: firebase.User | null;
	authenticated: boolean;
	userDoc: firestore.DocumentData | null;
	userLoaded: boolean;
	setUserLoaded: any;
	error: string;
};

export const UserContext = React.createContext<Partial<ContextProps>>({});

export const UserProvider = ({ children }: any) => {
	const [user, setUser] = useState(null as firebase.User | null);
	const [userDoc, setUserDoc] = useState(null as firestore.DocumentData | null);
	const [userLoaded, setUserLoaded] = useState(false);
	const [error, setError] = useState("");

	const clearUserState = () => {
		setUser(null);
		setUserDoc(null);
		setError("");
		setUserLoaded(true);
	}


	useEffect(() => {
		auth().onAuthStateChanged((authUser: any) => {
			if (!authUser) {
				clearUserState();
				setError("None");
			} else {
				setUserLoaded(false);
				setUser(authUser);

				(async () => {
					const userRef = authdb.collection('users').doc(authUser.uid);
					const userDoc = await userRef.get();
					if (!userDoc.exists) {
						setError("No User Doc Found");
						return setUserLoaded(true);
					}
					setUserDoc(userDoc.data());

					setError("None");
				})();
			}

		});
	}, []);


	useEffect(() => {
		if (user && userDoc && error) {

			if (!userDoc) {
				setError("Sorry your access has not been set up");
			}
			setUserLoaded(true);
		}
	}, [user, userDoc, error]);


	return (
		<UserContext.Provider
			value={{
				user,
				authenticated: user !== null,
				userDoc,
				userLoaded,
				setUserLoaded,
				error
			}}>
			{children}
		</UserContext.Provider>
	);

}

export default UserProvider;

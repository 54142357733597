import { Theme } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { themeOverrides, themeOverridesDark } from "./themeOverrides";

export function themeCreator(theme: string, type: string): Theme {
	if(type === "dark"){ 
		if (themeMapDark[theme]) {
			return { ...themeMapDark[theme] };
		} else {
			return { ...themeMapDark["main"] };
		}
	}
	if (themeMap[theme]) {
		return { ...themeMap[theme] };
	} else {
		return { ...themeMap["main"] };
	}
}




const mainTheme = createTheme({
	palette: {
		primary: {
			main: "#990000"
		},
		secondary: {
			main: "#550000"
		},
		background: {
			default: "#f5f5f5"
		}
	},
	overrides: themeOverrides
});


const mainThemeDark = createTheme({
	palette: {
		type:"dark",
		primary: {
			main: "#990000"
		},
		secondary: {
			main: "#d7ccc8"
		}
	},
	overrides: themeOverridesDark
});



const themeMap: any = {
	main: mainTheme
};


const themeMapDark: any = {
	main: mainThemeDark
};
import React, { useState } from 'react';
import { ThemeProvider } from '@material-ui/core';
import { themeCreator } from '../themes/pageThemes';

export const ThemeContext = React.createContext({ themeName: 'mainTheme', themeType: 'light', setTheme: (themeName: string) => { }, setThemeType: (type: string) => { }  });

const CustomThemeProvider: React.FC = (props) => {
	// State to hold the selected theme name

	let userpref = (localStorage.getItem('themeType') === 'dark')?'dark':'light';
	const [themeName, setTheme] = useState('main');
	const [themeType, setThemeType] = useState(userpref);
	const theme = themeCreator(themeName,themeType);

	function setThemeTypePref(type:string){
		localStorage.setItem('themeType',type);
		setThemeType(type);
	}

	const contextValue = {
		themeName: themeName,
		setTheme: setTheme,
		themeType: themeType,
		setThemeType: setThemeTypePref
	};

	return (
		<ThemeContext.Provider value={contextValue}>
			<ThemeProvider theme={theme}>{props.children}</ThemeProvider>
		</ThemeContext.Provider>
	);
};

export default CustomThemeProvider;

import React, { useEffect, useContext } from "react";

//ui
import Paper from "@material-ui/core/Paper";
import { IconSpin } from '../../components/Icons'
import '../../home.css'

//providers
import { UserContext } from "../../providers/UserProvider";

import { auth } from "../../services/firebase";

import Config from "../../config";
import Logo from '../../assets/home-app-logo.svg';
import LogoDark from '../../assets/home-app-logo.svg';
import { Button } from "@material-ui/core";


const Home = (props: any) => {

	const userContext: any = useContext(UserContext);

	const pageTitle = "";

	const pageButtons: any = [];

	const pageCallbacks = async () => {
		props.setTheme("main");
		props.setPageTitle(pageTitle);
		props.setPageButtons(pageButtons);

	};

	useEffect(() => {
		pageCallbacks();

		if ('serviceWorker' in navigator) {
			navigator.serviceWorker.getRegistrations().then(function (registrations) {
				for (let registration of registrations) {
					registration.update()
				}
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Paper style={{ padding: "1em", textAlign: "center" }}>
				{!userContext.userLoaded ? (
					<>
						<h1><IconSpin name="spinner-third" />	 </h1>
						<h4>&nbsp;</h4>
					</>
				) : (
						<>
							{userContext.error !== "None" ? (
								<>
									<h3> Sorry, something went wrong</h3>
									<p>
										<Button
											color="secondary"
											variant="contained"
											onClick={() => {
												auth()
													.signOut();
												//.then(() => window.location.href = "/");

											}}
										>
											Sign Out
										</Button>
									</p>
								</>
							) : (
									<>
										<h1>Home</h1>
										<h4>Mobile App</h4>
									</>
								)
							}</>
					)}
			</Paper>
			<p></p>
			<p></p>
			<p></p>
			<Paper style={{ padding: "1em", textAlign: "center" }}>
				<img alt="Logo" src={(props.themeType === 'dark') ? LogoDark : Logo} style={{ width: "80%", maxWidth: "500px" }} />
			</Paper>
			<p>Build Version: {Config.buildVersion}</p>

		</>
	);
};

export default Home;
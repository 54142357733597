import React, { useState, useContext, lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

//material-ui
import Container from "@material-ui/core/Container";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { AppBar, Toolbar, Typography, IconButton, Badge } from "@material-ui/core";
import { Icon } from "../components/Icons";

//helpers
import clsx from "clsx";

//providers
import { ThemeContext } from "../providers/CustomThemeProvider";
import { UserContext } from "../providers/UserProvider";

//local
import PageButtons from "./PageButtons";
import NavDrawer, { drawerWidth } from "./NavDrawer";

import Home from "../pages/Home";
import SignIn from "../pages/SignIn";

//pages
const Coop = lazy(() => import("../pages/Coop"));
const Settings = lazy(() => import("../pages/Settings"));

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: "flex",
	},
	appBar: {
		transition: theme.transitions.create(["margin", "width"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: drawerWidth,
		transition: theme.transitions.create(["margin", "width"], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	toolbarButtons: {
		marginLeft: "auto",
		display: "flex",
		flexDirection: "row-reverse",
	},
	hide: {
		display: "none",
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
	},
	drawerHeader: {
		display: "flex",
		alignItems: "center",
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
		justifyContent: "flex-end",
	},
	navLink: {
		textDecoration: "none",
		color: "black",
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
		transition: theme.transitions.create("margin", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		marginLeft: drawerWidth,
	},
	contentShift: {
		transition: theme.transitions.create("margin", {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginLeft: 0,
	},
	profile: {
		fontSize: ".8em",
		position: "absolute",
		backgroundColor: theme.palette.info.main,
	},
	iframe: {
		width: "100vw",
		height: "100%",
		border: "none",
		// Offset container padding
		margin: -theme.spacing(3),
		position: "absolute",
	},
}));

const Topbar = ({ updateAvailable, applyUpdate }: any) => {
	const classes = useStyles();
	const [drawerOpen, setDrawerOpen] = useState(false);
	const [pageTitle, setPageTitle] = useState("");
	const [pageButtons, setPageButtons] = useState([]);
	const { setTheme, themeType } = useContext(ThemeContext);
	const userContext: any = useContext(UserContext);

	return (
		<>
			<BrowserRouter>
				{userContext.userLoaded && !userContext.user ? (
					<AppBar id="appHeader" position="sticky">
						<Toolbar>
							<Typography variant="h6" noWrap>
								{pageTitle}
							</Typography>
						</Toolbar>
					</AppBar>
				) : (
					<AppBar
						id="appHeader"
						position="sticky"
						className={clsx(classes.appBar, {
							[classes.appBarShift]: drawerOpen,
						})}
					>
						<Toolbar>
							<IconButton
								color="inherit"
								aria-label="open drawer"
								onClick={() => setDrawerOpen(true)}
								edge="start"
								className={clsx(classes.menuButton, drawerOpen && classes.hide)}
							>
								<Badge color="error" variant="dot" invisible={!updateAvailable}>
									<Icon name="bars" />
								</Badge>
							</IconButton>

							<Typography variant="h6" noWrap>
								{pageTitle}
							</Typography>
							<div className={classes.toolbarButtons}>
								<PageButtons pageButtons={pageButtons} />
							</div>
						</Toolbar>
						<NavDrawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} updateAvailable={updateAvailable} applyUpdate={applyUpdate} />
					</AppBar>
				)}
				<Suspense fallback={<div>Loading... </div>}>
					<Container
						maxWidth="xl"
						className={clsx([classes.content], {
							[classes.contentShift]: !drawerOpen,
						})}
					>
						<main>
							<Routes>
								{!userContext.userLoaded || !userContext.user ? (
									<>
										{userContext.userLoaded && !userContext.user ? (
											<Route
												path="/"
												element={<SignIn setPageButtons={setPageButtons} setPageTitle={setPageTitle} setTheme={setTheme} themeType={themeType} />}
											/>
										) : (
											<Route
												path="/"
												element={<Home setPageButtons={setPageButtons} setPageTitle={setPageTitle} setTheme={setTheme} themeType={themeType} />}
											/>
										)}
									</>
								) : null}
								<Route path="/" element={<Home setPageButtons={setPageButtons} setPageTitle={setPageTitle} setTheme={setTheme} themeType={themeType} />} />
								<Route path="/coop" element={<Coop setPageButtons={setPageButtons} setPageTitle={setPageTitle} setTheme={setTheme} />} />
								<Route
									path="/settings"
									element={<Settings setPageButtons={setPageButtons} setPageTitle={setPageTitle} setTheme={setTheme} themeType={themeType} />}
								/>
							</Routes>
						</main>
					</Container>
				</Suspense>
			</BrowserRouter>
		</>
	);
};

export default Topbar;

import React, { useState, useEffect } from 'react';
import Config from '../config';

//material-ui
import { Button, Grid, TextField, FormControl} from '@material-ui/core';

//services
import { auth, signInWithGoogle } from '../services/firebase';

//providers
import { useSnacks } from '../providers/SnackProvider';

const SignIn = (props: any) => {
	const [ email, setEmail ] = useState('');
	const [ password, setPassword ] = useState('');

	const [ openSnack ] = useSnacks();

	const pageTitle = 'Sign In';

	const pageButtons: any = [ {} ];

	const pageCallbacks = async () => {
		props.setTheme('main');
		props.setPageTitle(pageTitle);
		props.setPageButtons(pageButtons);
	};

	useEffect(() => {
		pageCallbacks();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const signInWithEmailAndPasswordHandler = (event: any, email: string, password: string) => {
		event.preventDefault();
		auth().signInWithEmailAndPassword(email, password).catch((error: any) => {
			openSnack('Error signing in with password and email!', 'warning');
			console.error('Error signing in with password and email', error);
		});
	};

	const onChangeHandler = (event: any) => {
		const { name, value } = event.currentTarget;

		if (name === 'userEmail') {
			setEmail(value);
		} else if (name === 'userPassword') {
			setPassword(value);
		}
	};

	const onEnterPress = (e:any) => {
		if(e.keyCode === 13 && e.shiftKey === false) {
		  e.preventDefault();
		  signInWithEmailAndPasswordHandler(e, email, password);
		}
	  }


	return (
		<Grid container spacing={3} style={{ margin: '0 auto' }} direction="column" alignItems="stretch" onKeyDown={onEnterPress}>
			<Grid item xs={12}>
				<FormControl fullWidth>
					<TextField
						label="Email"
						type="email"
						name="userEmail"
						value={email}
						onChange={(e) => onChangeHandler(e)}
					/>
				</FormControl>
			</Grid>
			<Grid item xs={12}>
				<FormControl fullWidth>
					<TextField
						label="Password"
						type="password"
						name="userPassword"
						value={password}
						onChange={(e) => onChangeHandler(e)}
					/>
				</FormControl>
			</Grid>
			<Grid item xs={12}>
				<FormControl style={{ width: '100%' }}>
					<Button
						onClick={(event) => {
							signInWithEmailAndPasswordHandler(event, email, password);
						}}
						variant="contained"
						color="primary"
						style={{ width: '100%' }}
					>
						{props.buttonContent || "Sign In"}
					</Button>
				</FormControl>
			</Grid>
			<Grid item xs={12}>
				<p>&nbsp;</p>
			</Grid>
			<Grid item xs={12}>
				<FormControl>
					<Button variant="outlined" color="primary" onClick={signInWithGoogle}>
						Sign in with Google
					</Button>
				</FormControl>
			</Grid>
			<Grid item xs={12}>
				<p>Build Version: {Config.buildVersion}</p>
			</Grid>
		</Grid>
	);
};
export default SignIn;

import React, { useContext, useState, useLayoutEffect } from "react";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

//Theme - material-ui
import CustomThemeProvider from "./providers/CustomThemeProvider";
import { CssBaseline } from "@material-ui/core";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faBackward, faForward, faStop } from "@fortawesome/free-solid-svg-icons";
import {
	faBrackets,
	faPlus,
	faEgg,
	faMinus,
	faTimes,
	faDivide,
	faEquals,
	faBackspace,
	faHome,
	faCalendarCheck,
	faTally,
	faBoxes,
	faChevronLeft,
	faChevronRight,
	faChevronDown,
	faChevronUp,
	faArrowAltUp,
	faCog,
	faExpand,
	faBars,
	faCaretSquareDown,
	faCaretSquareUp,
	faCloudDownload,
	faClipboardListCheck,
	faSync,
	faCalculatorAlt,
	faCamera,
	faCalendarPlus,
	faCompass,
	faInfo,
	faArrowLeft,
	faWindowClose,
	faMapMarkerAlt,
	faSpinnerThird,
	faCheck,
	faTimesCircle,
	faExclamationTriangle,
	faHourglass,
	faRetweet,
	faRepeat,
	faCalendarAlt,
	faBan,
	faClock,
	faTrash,
	faEdit,
	faRedo,
	faQuestionCircle,
	faQuestion,
} from "@fortawesome/pro-regular-svg-icons";

//providers
import { ConfirmProvider } from "material-ui-confirm";
import { SnackbarProvider } from "notistack";
import { UserContext } from "./providers/UserProvider";
import { AWSCognitoCredentialsProvider } from "./providers/awsCognitoCredentialsProvider";
import mqttServiceAws from "./services/mqttServiceAws";
import { Connector } from "mqtt-react-hooks";

import { IClientOptions } from "mqtt";

import Topbar from "./layout/Topbar";
import { random } from "lodash";

library.add(
	faBrackets,
	faPlus,
	faEgg,
	faMinus,
	faTimes,
	faDivide,
	faEquals,
	faBackspace,
	faHome,
	faCalendarCheck,
	faTally,
	faBoxes,
	faChevronLeft,
	faChevronRight,
	faChevronDown,
	faChevronUp,
	faArrowAltUp,
	faCog,
	faExpand,
	faBars,
	faCaretSquareDown,
	faCaretSquareUp,
	faCloudDownload,
	faClipboardListCheck,
	faSync,
	faCalculatorAlt,
	faCamera,
	faCalendarPlus,
	faCompass,
	faInfo,
	faArrowLeft,
	faWindowClose,
	faMapMarkerAlt,
	faSpinnerThird,
	faCheck,
	faTimesCircle,
	faExclamationTriangle,
	faHourglass,
	faRetweet,
	faRepeat,
	faCalendarAlt,
	faBan,
	faClock,
	faTrash,
	faEdit,
	faRedo,
	faBackward,
	faForward,
	faStop,
	faQuestionCircle,
	faQuestion
);

const App = () => {
	const userContext = useContext(UserContext);
	const [updateAvailable, setUpdateAvailable] = useState(false);
	const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(null);

	const onSWUpdate = (registration: ServiceWorkerRegistration) => {
		setUpdateAvailable(true);
		setWaitingWorker(registration.waiting);
	};

	const onSWSuccess = (registration: ServiceWorkerRegistration) => {
		console.log("sw success");
		console.log(registration);
	};

	const applyUpdate = () => {
		waitingWorker?.postMessage({ type: "SKIP_WAITING" });
		setUpdateAvailable(false);
		window.location.reload();
	};

	useLayoutEffect(() => {
		serviceWorkerRegistration.register({ onUpdate: onSWUpdate, onSuccess: onSWSuccess });
	}, []);

	if (!userContext || !userContext.userLoaded || !userContext.user) {
		return (
			<>
				<CssBaseline />
				<CustomThemeProvider>
					<SnackbarProvider
						dense
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "center",
						}}
					>
						<ConfirmProvider>
							<CssBaseline />
							<Topbar updateAvailable={updateAvailable} applyUpdate={applyUpdate} />
						</ConfirmProvider>
					</SnackbarProvider>
				</CustomThemeProvider>
			</>
		);
	}

	//const mqttOptions: IClientOptions = {keepalive: 60, username: userContext.userDoc?.uuid, password: "whocares"};
	//const mqttBrokerUrl = "mqtts://" + localStorage.getItem("mqttendpoint") + ":8883" || "";

	return (
		<>
			<CssBaseline />
			<CustomThemeProvider>
				<SnackbarProvider
					dense
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "center",
					}}
				>
					<ConfirmProvider>
						<CssBaseline />
						<Topbar updateAvailable={updateAvailable} applyUpdate={applyUpdate} />
					</ConfirmProvider>
				</SnackbarProvider>
			</CustomThemeProvider>
		</>
	);
};

export default App;

import React from "react";
import ReactDOM from "react-dom";
import App from "./app";
import UserProvider from "./providers/UserProvider";
//import Config from "./config";
//import Dexie from "dexie";
/*
var db = new Dexie("AppDB");
db.open().then(function (db) {
	//console.log("Database is at version: " + db.verno);
	if (db.verno > Config.dexieVersion) {
		console.log("Client db is newer");
		db.delete();
		db.close();
	}
	*/
	ReactDOM.render(
		<React.StrictMode>
				<UserProvider>
					<App />
				</UserProvider>
		</React.StrictMode>,
		document.getElementById("root")
	);
//});

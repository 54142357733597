import React from "react";
import { useSnackbar } from "notistack";
import Button from "@material-ui/core/Button";

export const useSnacks = (): any => {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const openSnack = (
		msg: React.ReactNode,
		variant: "default" | "error" | "success" | "warning" | "info" | undefined = "default",
		persist: boolean = false,
		action: any = ""
	): string | number => {
		if (!action && persist) {
			action = (key: string | number | undefined) => (
				<Button
					color="inherit"
					variant="outlined"
					onClick={() => {
						closeSnackbar(key);
					}}
				>
					Got It
				</Button>
			);
		}

		return enqueueSnackbar(msg, {
			variant: variant,
			persist: persist,
			action: action
		});
	};

	const closeSnack = (key: string | number) => closeSnackbar(key);

	return [openSnack, closeSnack];
};

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore"
import Config from "../config";

firebase.initializeApp(Config.firebaseConfig);
export const auth: any = firebase.auth;
export const authdb: any = firebase.firestore();
authdb.enablePersistence()
  .catch(function(err:any) {
      if (err.code === 'failed-precondition') {
          // Multiple tabs open, persistence can only be enabled
          // in one tab at a a time.
          // ...
      } else if (err.code === 'unimplemented') {
          // The current browser does not support all of the
          // features required to enable persistence
          // ...
      }
  });
const provider = new auth.GoogleAuthProvider();
export const signInWithGoogle = () => {
	auth().signInWithPopup(provider);
};

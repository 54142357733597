import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

//material-ui
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Drawer, List, Divider, ListItem, ListItemIcon, ListItemText, IconButton, Badge } from '@material-ui/core';

import { Icon } from '../components/Icons';

//providers
import { UserContext } from '../providers/UserProvider';

export const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex'
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0
	},
	drawerPaper: {
		width: drawerWidth
	},
	drawerHeader: {
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
		justifyContent: 'flex-end'
	},
	navLink: {
		textDecoration: 'none',
		color: 'inherit'
	}
})
);


	//navigation menu
	const navMenu = [
		{
			name: 'Home',
			access: '',
			path: '/',
			icon: <Icon name="home" size="lg" />
		},
		{
			name: 'COOP',
			access: 'coop',
			path: '/coop',
			icon: <Icon name="egg" size="lg" />
		}
	];


function NavDrawer(props: any) {

	const { drawerOpen, setDrawerOpen, updateAvailable, applyUpdate } = props;

	const classes = useStyles();
	const theme = useTheme();

	const userContext = useContext(UserContext);



	const toggleFullScreen = () => {
		var doc = window.document;
		var docEl = doc.documentElement;

		var requestFullScreen = docEl.requestFullscreen;
		var cancelFullScreen = doc.exitFullscreen;

		if (!doc.fullscreenElement) {
			requestFullScreen.call(docEl);
		} else {
			cancelFullScreen.call(doc);
		}
	};

	if(!userContext.userLoaded || !userContext.user){
		return <></>
	}


	return (
		<Drawer
			className={classes.drawer}
			variant="persistent"
			anchor="left"
			open={drawerOpen}
			classes={{
				paper: classes.drawerPaper
			}}
		>
			<div className={classes.drawerHeader}>
				<IconButton onClick={() => setDrawerOpen(false)}>
					{theme.direction === 'ltr' ? <Icon name="chevron-left" /> : <Icon name="chevron-right" />}
				</IconButton>
			</div>
			<Divider />
			<List>
				{navMenu.map(function (item, index) {
					if (item.access === '' || userContext.userDoc?.access[item.access]) {
						return (
							<Link
								to={item.path}
								key={index}
								className={classes.navLink}
								onClick={() => setDrawerOpen(false)}
							>
								<ListItem button key={index}>
									<ListItemIcon>{item.icon}</ListItemIcon>
									<ListItemText primary={item.name} />
								</ListItem>
							</Link>
						);
					} else {
						return null;
					}
				})}
			</List>
			<Divider />
			<List>
				{updateAvailable ? (
					<ListItem button key="Update" onClick={applyUpdate}>
						<ListItemIcon>
							<Badge color="error" variant="dot">
								<Icon name="arrow-alt-up" size="lg" />
							</Badge>
						</ListItemIcon>
						<ListItemText primary="Update App" />
					</ListItem>) : null
				}

				<Link to={'/settings'} className={classes.navLink} onClick={() => setDrawerOpen(false)}>
					<ListItem button>
						<ListItemIcon>
							<Icon name="cog" size="lg" />
						</ListItemIcon>
						<ListItemText primary="Settings" />
					</ListItem>
				</Link>
				<List>
				</List>
			</List>
			<List>
				<ListItem button key="Settings" onClick={toggleFullScreen}>
					<ListItemIcon>
						<Icon name="expand" size="lg" />
					</ListItemIcon>
					<ListItemText primary="Full Screen" />
				</ListItem>
			</List>
		</Drawer>
	);
}

export default NavDrawer;
